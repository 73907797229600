import React from 'react'

import { Icon } from '../Icon'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const CircleButton: React.FC<IProps> = ({
  iconName,
  className,
  badge,
  label,
  isActive = false,
  ...otherProps
}): JSX.Element => {
  const classNames = `${Styles.circleButton} ${isActive ? Styles.active : ''} ${className ?? ''}`

  return (
    <button type="button" className={classNames} {...otherProps} data-badge={badge}>
      <Icon name={iconName} />

      {label && <div className={Styles.tooltip}>{label}</div>}
    </button>
  )
}
