import React from 'react'

import { Typography } from '../Typography'
import { IProps } from './interface'
import Styles from './styles.module.scss'

export const StatusLabel: React.FC<IProps> = ({ label, variant, testid }): JSX.Element => {
  return (
    <div className={`${Styles.statusLabel} ${Styles[variant]}`} data-testid={testid || null}>
      <Typography variant="h4" element="p" className={Styles.statusLabelBody}>
        {label}
      </Typography>
    </div>
  )
}
