import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../Components'
import { Icon } from '../../../../../Components/Icon'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const PollingNotification: React.FC<IProps> = ({ year }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={Styles.container}>
      <Icon name="data-loading" className={Styles.icon} />

      <Typography variant="h1-large" className={Styles.title}>
        {t('Data is being retrieved')}
      </Typography>

      <Typography variant="body-1" className={Styles.body}>
        {t('The data for the {{ year }} overview is now being calculated and retrieved; this may take a few minutes.', {
          year,
        })}
      </Typography>
    </div>
  )
}
