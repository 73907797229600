import React from 'react'

import { StatusLabel } from '../../../../../../../Components/StatusLabel'
import { Typography } from '../../../../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Statistics: React.FC<IProps> = ({ header, data }): JSX.Element => {
  return (
    <div className={Styles.column}>
      <div className={Styles.header}>
        <Typography variant="h2" className={Styles.label}>
          {header}
        </Typography>
      </div>

      <ul className={Styles.list}>
        {data.map((row) => (
          <li key={row.label}>
            <Typography className={Styles.label} variant="body-2" element="span">
              {row.label}
            </Typography>
            <StatusLabel label={`${row.total}`} variant="gray" />
          </li>
        ))}
      </ul>
    </div>
  )
}
