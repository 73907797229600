import React, { useRef, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import AvatarEditor from 'react-avatar-editor'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../../Button'
import { Modal } from '../../../../../../Modal'
import { ConfirmFooter, ContentSection } from '../../../../../../Modal/Components'
import { Typography } from '../../../../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ImageCropperModal: React.FC<IProps> = ({
  source,
  onReupload,
  onApply,
  size,
  onClose,
}): JSX.Element | null => {
  const ref = useRef<AvatarEditor>(null)
  const { t } = useTranslation()
  const [scale, setScale] = useState<number>(1)
  const [rotation, setRotation] = useState<number>(0)

  const handleUpload = (): void => {
    const img = ref?.current?.getImageScaledToCanvas()
    const capturedBase64Image = img?.toDataURL('base64')

    if (capturedBase64Image) onApply(capturedBase64Image)
    onClose()
  }

  const rotateHandler = (side: 'left' | 'right'): void => {
    setRotation(side === 'right' ? rotation + 90 : rotation - 90)
  }

  const handleScale = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setScale(event.target.valueAsNumber)
  }

  const reUploadHandler = (): void => {
    onReupload()
    onClose()
  }

  const renderFooter = (): JSX.Element => {
    return (
      <ConfirmFooter confirmProps={{ onClick: handleUpload, label: t('Apply') }} cancelProps={{ onClick: onClose }} />
    )
  }

  return (
    <Modal id="image-cropper" title={t('Crop image')} onClose={onClose} footer={renderFooter()} show>
      <ContentSection>
        <div className={Styles.fileWrapper}>
          <AvatarEditor
            className={Styles.avatarEditor}
            image={source}
            border={50}
            ref={ref}
            width={size.width}
            height={size.height}
            scale={scale}
            rotate={rotation}
          />

          <div className={Styles.sliderContainer}>
            <Typography className={Styles.label} variant="body-3">
              {t('Zoom')}
            </Typography>
            <input
              id="photoRangeSlider"
              className={Styles.slider}
              type="range"
              value={scale}
              step={0.01}
              min={1}
              max={8}
              onChange={handleScale}
            />
          </div>

          <div className={Styles.spinContainer}>
            <Typography className={Styles.label} variant="body-3">
              {t('Spin')}
            </Typography>

            <div className={Styles.buttonsWrapper}>
              <Button variant="secondary" icon="arrow-left" onClick={(): void => rotateHandler('left')}>
                {t('Left')}
              </Button>

              <Button variant="secondary" icon="arrow-right" onClick={(): void => rotateHandler('right')}>
                {t('Right')}
              </Button>

              <Button variant="secondary" icon="upload" onClick={reUploadHandler}>
                {t('Re-upload')}
              </Button>
            </div>
          </div>
        </div>
      </ContentSection>
    </Modal>
  )
}
