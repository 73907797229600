import { format } from 'date-fns'
import { t } from 'i18next'

import { TIconName } from '../../../Components/Icon/iconNames'
import { ITableConfig } from '../../../Components/Table/interfaces'
import { formatDateString } from '../../../Lib/Formatters/DateFormatter'
import { IGetWrpmQuery, IV2BudgetGroup, IWrpmModalityEnum, IWrpmSurveyStatusEnum } from '../../../Lib/graphql'
import { TLocale } from '../../../Lib/sharedInterfaces'
import {
  IBudgetGroup,
  IBudgetGroupOption,
  IBudgetGroupWithSurvey,
  IStatistics,
  ITransformedWRPMRow,
  IYearOptions,
  TRespondentsAndInvitees,
  TStatisticsForBudgetGroup,
} from './interfaces'

export const getTableConfig = (): ITableConfig => {
  return {
    kind: {
      name: t('Kind'),
      width: '300px',
    },
    commute_kms: {
      name: t('Commute'),
      width: '200px',
    },
    commute_co2: {
      name: t('Commute CO2'),
      width: '200px',
    },
    business_kms: {
      name: t('Business'),
      width: '200px',
    },
    business_co2: {
      name: t('Business CO2'),
      width: '200px',
    },
  }
}

export const transformWRPMData = (WRPMData: IGetWrpmQuery): ITransformedWRPMRow[] => {
  const { wrpm } = WRPMData.reisbalansV2

  if (!wrpm || !wrpm.length) return []

  const transformedWRPMData = wrpm.map((item): ITransformedWRPMRow => {
    return {
      id: item?.modality?.value || '',
      kind: item?.modality?.value || null,
      humanized: item?.modality?.humanized || '',
      commute_kms: parseInt(item?.statsPerModalityCommute?.totalKms, 10) || 0,
      commute_co2: parseInt(item?.statsPerModalityCommute?.totalCo2, 10) || 0,
      business_kms: parseInt(item?.statsPerModalityBusiness?.totalKms, 10) || 0,
      business_co2: parseInt(item?.statsPerModalityBusiness?.totalCo2, 10) || 0,
    }
  })

  const cumulativeRow = transformedWRPMData.reduce(
    (acc, value): ITransformedWRPMRow => {
      acc.commute_kms += value.commute_kms
      acc.commute_co2 += value.commute_co2
      acc.business_kms += value.business_kms
      acc.business_co2 += value.business_co2

      return acc
    },
    {
      id: 'total',
      kind: null,
      humanized: t('Total'),
      commute_kms: 0,
      commute_co2: 0,
      business_kms: 0,
      business_co2: 0,
    }
  )

  return [cumulativeRow, ...transformedWRPMData]
}

export const mapModalityEnumToIconName = (value: IWrpmModalityEnum): TIconName => {
  switch (value) {
    case IWrpmModalityEnum.CAR_PETROL:
    case IWrpmModalityEnum.CAR_DIESEL:
    case IWrpmModalityEnum.CAR_HYBRID:
    case IWrpmModalityEnum.CAR_ELECTRIC:
    case IWrpmModalityEnum.CAR_OTHER:
      return 'car'

    case IWrpmModalityEnum.MOTORCYCLE_PETROL:
    case IWrpmModalityEnum.MOTORCYCLE_ELECTRIC:
      return 'motorcycle'

    case IWrpmModalityEnum.SCOOTER_PETROL:
    case IWrpmModalityEnum.SCOOTER_ELECTRIC:
      return 'scooter'

    case IWrpmModalityEnum.LEGS_OR_BICYCLE:
      return 'bicycle'

    case IWrpmModalityEnum.OV:
      return 'public-transport'

    default:
      return 'receipt'
  }
}

export const getLastInvitation = (budgetGroups: IBudgetGroupWithSurvey[], locale: TLocale): string | null => {
  const invitedBudgetGroups = budgetGroups
    .filter(({ openWrpmSurvey }) => !!openWrpmSurvey)
    .sort(
      // eslint-disable-next-line @getify/proper-arrows/params
      (a, b) => new Date(b.openWrpmSurvey.inviteSentAt).getTime() - new Date(a.openWrpmSurvey.inviteSentAt).getTime()
    )

  if (!invitedBudgetGroups.length) return null

  return formatDateString(new Date(invitedBudgetGroups[0].openWrpmSurvey?.inviteSentAt), 'd MMMM Y', locale)
}

export const getLastReminder = (budgetGroups: IBudgetGroupWithSurvey[], locale: TLocale): string | null => {
  const invitedBudgetGroups = budgetGroups
    .filter(({ openWrpmSurvey }) => !!openWrpmSurvey)
    .sort(
      // eslint-disable-next-line @getify/proper-arrows/params
      (a, b) =>
        new Date(b.openWrpmSurvey.lastReminderSentAt).getTime() -
        new Date(a.openWrpmSurvey.lastReminderSentAt).getTime()
    )

  if (!invitedBudgetGroups.length || !invitedBudgetGroups[0].openWrpmSurvey?.lastReminderSentAt) return null

  return formatDateString(new Date(invitedBudgetGroups[0].openWrpmSurvey?.lastReminderSentAt), 'd MMMM Y', locale)
}

export const calculateSample = (numEmployees: number): number => {
  // For more info regarding the constants below visit the following link https://mobiliteitsfabriek.atlassian.net/wiki/x/BYD9BAE
  const z = 1.96
  const N = numEmployees
  const p = 0.5
  const F = 0.05

  return Math.ceil((N * z ** 2 * p * (1 - p)) / (z ** 2 * p * (1 - p) + (N - 1) * F ** 2))
}

const calculateThresholdMet = (respondents: number, invitees: number): boolean => {
  const sample = calculateSample(invitees)
  return respondents >= sample
}

export const calculateStatisticsForBudgetGroup = (
  respondents: number,
  invitees: number
): TStatisticsForBudgetGroup | null => {
  if (!invitees) return null

  const sample = calculateSample(invitees)
  const thresholdMet = respondents >= sample
  const actualPercentage = Math.round((respondents * 100) / invitees)
  const goalPercentage = Math.round((sample * 100) / invitees)

  return { thresholdMet, actualPercentage, goalPercentage }
}

export const calculateStatistics = (budgetGroups: IBudgetGroupWithSurvey[], locale: TLocale): IStatistics => {
  const respondentsAndInvitees = budgetGroups
    .filter(({ openWrpmSurvey }) => !!openWrpmSurvey?.inviteSentAt)
    .reduce((acc: TRespondentsAndInvitees, budgetGroup) => {
      if (!acc.respondents && !acc.invitees) {
        acc.respondents = 0
        acc.invitees = 0
      }

      acc.respondents += budgetGroup.openWrpmSurvey.respondents
      acc.invitees += budgetGroup.openWrpmSurvey.invitees
      return acc
    }, {} as TRespondentsAndInvitees)

  return {
    lastInvitation: getLastInvitation(budgetGroups, locale),
    lastReminder: getLastReminder(budgetGroups, locale),
    respondents: respondentsAndInvitees.respondents,
    invitees: respondentsAndInvitees.invitees,
    thresholdMet: calculateThresholdMet(respondentsAndInvitees.respondents, respondentsAndInvitees.invitees),
  }
}

export const isDataDefinitive = (budgetGroups: IBudgetGroup[]): boolean => {
  return budgetGroups.every(({ wrpmSurvey }) => wrpmSurvey?.status === IWrpmSurveyStatusEnum.DEFINITIVE)
}

export const hasOpenSurveys = (budgetGroups: IBudgetGroup[]): boolean => {
  return budgetGroups.some(
    ({ openWrpmSurvey }) => openWrpmSurvey?.status === IWrpmSurveyStatusEnum.OPEN && !!openWrpmSurvey?.inviteSentAt
  )
}

export const downloadAsFile = (base64: string): void => {
  const linkSource = `data:application/octet-stream;base64,${base64}`
  const downloadLink = document.createElement('a')

  downloadLink.href = linkSource
  downloadLink.download = `wrpm-export-${format(new Date(), 'yyyy-MM-dd')}.xlsx`
  downloadLink.click()
}

export const generateYearArray = (): IYearOptions[] => {
  const minimumYear = 2024
  const currentYear = new Date().getFullYear() // On january 1st the new year needs to be added to the array
  const arrayYear = []

  for (let i = minimumYear; i <= currentYear; i += 1) arrayYear.push({ label: i.toString(), value: i })

  return arrayYear
}

export const makeBudgetGroupOptions = (budgetGroups: IV2BudgetGroup[]): IBudgetGroupOption[] => {
  if (!budgetGroups.length) return []

  const allOption: IBudgetGroupOption = {
    label: t('All budgetgroups'),
    value: null,
  }

  const options = budgetGroups.map((budgetGroup): IBudgetGroupOption => {
    return {
      label: budgetGroup.name!,
      value: budgetGroup,
    }
  })

  return [allOption, ...options]
}
