import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

const ExpandableText: React.FC<IProps> = ({ text }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpansion = (): void => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={Styles.expandableTextContainer}>
      <span className={`${Styles.expandableText} ${isExpanded ? Styles.expanded : ''}`}>{text}</span>

      <div onClick={toggleExpansion} className={Styles.toggleButton}>
        {isExpanded ? t('Less') : t('More')}
      </div>
    </div>
  )
}

export default ExpandableText
