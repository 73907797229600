import React, { FormEvent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from '../../Components'
import { Checkbox } from '../../Components/FormComponents/Checkbox'
import { Input } from '../../Components/FormComponents/Input'
import { AuthContext } from '../../Providers/AuthProvider'
import Styles from './styles.module.scss'

export const Login: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { login } = useContext(AuthContext)

  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [otp, setOtp] = useState<string>()
  const [remember, setRemember] = useState<boolean>(false)

  const handleSubmit = (event: FormEvent): void => {
    const redirect = state?.redirect
    event.preventDefault()

    login(email, password, otp, remember, redirect)
      .then((token) => {
        if (!token) toast.error(t('Email or password is incorrect. Please try again.'))
      })
      .catch(() => {
        toast.error(t('Email or password is incorrect. Please try again.'))
      })
  }

  return (
    <div className={Styles.loginPage}>
      <div className={Styles.panel}>
        <h1>{t('Login')}</h1>
        <form onSubmit={handleSubmit}>
          <Input
            id="email"
            autoCapitalize="off"
            autoCorrect="off"
            onChange={setEmail}
            placeholder={t('E-mail')}
            defaultValue={email}
            spellCheck="false"
          />

          <Input
            autoCorrect="off"
            id="password"
            onChange={setPassword}
            placeholder={t('Password')}
            spellCheck="false"
            defaultValue={password}
            type="password"
          />

          <Input
            autoCorrect="off"
            id="otp"
            onChange={setOtp}
            placeholder={t('2fa code')}
            spellCheck="false"
            defaultValue={otp}
            type="password"
          />

          <Checkbox id="remember" onChange={setRemember} checked={remember} label={t('Remember me')} />

          <Button id="submit-login" data-testid="submit-login" type="submit">
            {t('Login')}
          </Button>
        </form>

        <pre>graphql_url: {import.meta.env.REACT_APP_GRAPHQL_URL}</pre>
      </div>
    </div>
  )
}
