import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Button, ConfirmModal, SideDrawer, Typography } from '../../../../../Components'
import { Sheet } from '../../../../../Components/SideDrawer/components/Sheet'
import { GetBudgetGroupsDocument, Maybe, useSendWrpmSurveyRemindersMutation } from '../../../../../Lib/graphql'
import { LayoutContext } from '../../../../../Providers/LayoutProvider'
import { calculateStatisticsForBudgetGroup } from '../../helpers'
import { IBudgetGroupWithSurvey } from '../../interfaces'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const SurveyResponseBudgetGroups: React.FC<IProps> = ({ onClose, budgetGroups }) => {
  const { t } = useTranslation()
  const { organizationId } = useContext(LayoutContext)

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [selectedBudgetGroup, setSelectedBudgetGroup] = useState<Maybe<string> | undefined>()

  const [sendSurveyReminder] = useSendWrpmSurveyRemindersMutation()

  const budgetGroupsWithStatistics = budgetGroups
    .filter((budgetGroup): budgetGroup is IBudgetGroupWithSurvey => !!budgetGroup.openWrpmSurvey?.inviteSentAt)
    .map((budgetGroup) => {
      const statistics = calculateStatisticsForBudgetGroup(
        budgetGroup.openWrpmSurvey?.respondents,
        budgetGroup.openWrpmSurvey?.invitees
      )
      return {
        ...budgetGroup,
        statistics,
      }
    })

  const sendReminder = (): void => {
    if (!organizationId || !selectedBudgetGroup) return

    sendSurveyReminder({
      variables: {
        budgetGroupNames: [selectedBudgetGroup],
        organizationId,
      },
      refetchQueries: [
        {
          query: GetBudgetGroupsDocument,
          variables: { organizationId },
        },
      ],
    })
      .then((response) => {
        const result = response.data?.reisbalansV2.sendWrpmSurveyReminders?.successes
        setShowConfirmModal(false)
        if (result) toast.success(t('Send reminder successfully'))
      })
      .catch(() => {
        toast.error(t('Something went wrong'))
      })
  }

  return (
    <SideDrawer title={t('Response per budget group')} size="xl" onClose={onClose}>
      <Sheet>
        <table className={Styles.tableWrapper}>
          <tbody>
            {budgetGroupsWithStatistics.map((budgetGroup) => {
              return (
                <tr className={Styles.row} key={budgetGroup.id}>
                  <td className={Styles.cell}>
                    <Typography variant="h2">{budgetGroup.name}</Typography>
                  </td>

                  <td className={Styles.cell}>
                    <Typography variant="body-2">{`${budgetGroup.openWrpmSurvey?.respondents}/${budgetGroup.openWrpmSurvey?.invitees}`}</Typography>
                  </td>

                  <td className={Styles.cell}>
                    {!budgetGroup.statistics && <Typography variant="body-2">{t('N/A')}</Typography>}

                    {budgetGroup.statistics && (
                      <Typography
                        variant="body-2"
                        className={`${Styles.respondentsStatus} ${
                          budgetGroup.statistics.thresholdMet ? Styles.thresholdMet : ''
                        }`}
                      >
                        {`${budgetGroup.statistics.actualPercentage}%`}{' '}
                        {`(${t('goal')} ${budgetGroup.statistics.goalPercentage}%)`}
                      </Typography>
                    )}
                  </td>

                  <td className={Styles.cell}>
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        setSelectedBudgetGroup(budgetGroup.name)
                        setShowConfirmModal(true)
                      }}
                    >
                      {t('Send reminder_short')}
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {showConfirmModal && (
          <ConfirmModal
            content={t('Send reminder email')}
            description={t(
              'Send an reminder email to all employees within this budget group who have not yet filled in their travel data.'
            )}
            confirmButtonProps={{
              onClick: sendReminder,
              content: t('Send reminder'),
            }}
            onCancel={(): void => setShowConfirmModal(false)}
          />
        )}
      </Sheet>
    </SideDrawer>
  )
}
