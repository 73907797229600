import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../../../Components'
import { InlineFields } from '../../../../../../../Components/FormComponents/InlineFields'
import { Input } from '../../../../../../../Components/FormComponents/Input'
import { Modal } from '../../../../../../../Components/Modal'
import { ConfirmFooter, ContentSection } from '../../../../../../../Components/Modal/Components'

export const Edit = (): JSX.Element => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [domain, setDomain] = useState<string>('')

  const clickHandler = (): void => setShow(true)
  const closeHandler = (): void => setShow(false)

  const canSubmit = !!name && !!domain
  const renderFooter = (): JSX.Element => {
    return (
      <ConfirmFooter
        cancelProps={{ onClick: closeHandler }}
        confirmProps={{ disabled: !canSubmit, label: t('Save'), onClick: closeHandler }}
      />
    )
  }

  const formType = t('General change')

  return (
    <React.Fragment>
      <Button variant="secondary" size="small" onClick={clickHandler}>
        {t('Edit', { context: 'short' })}
      </Button>

      {show && (
        <Modal
          title={t('Customer label - {{ formType }}', { formType })}
          onClose={closeHandler}
          footer={renderFooter()}
          show
        >
          <ContentSection>
            <InlineFields>
              <Input id="name" label={t('Name customer label')} value={name} onChange={setName} required />
              <Input id="domain" label={t('Domain')} value={domain} onChange={setDomain} required />
            </InlineFields>
          </ContentSection>
        </Modal>
      )}
    </React.Fragment>
  )
}
