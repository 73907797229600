import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Components'
import { TextArea } from '../../../../../Components/FormComponents/TextArea'
import { Modal } from '../../../../../Components/Modal'
import { ConfirmFooter, ContentSection } from '../../../../../Components/Modal/Components'
import { MultiSelect } from '../../../../../Components/MultiSelect'
import { Panel } from '../../../../../Components/Panel'
import { Section } from '../../../../../Components/Panel/Components/Content/Section'
import { Value } from '../Value'
import { TProps } from './interfaces'
import Styles from './styles.module.scss'

export const FieldPanel: React.FC<TProps> = ({ id, title, type, value, values, options }): JSX.Element => {
  const { t } = useTranslation()
  const [textAreaValue, setTextAreaValue] = useState<typeof value>(value)
  const [multiSelectValue, setMultiSelectValue] = useState<typeof values>(values)

  const [showEditor, setShowEditor] = useState<boolean>(false)

  const closeModal = (): void => setShowEditor(false)
  const renderFooter = (): JSX.Element => {
    return (
      <ConfirmFooter confirmProps={{ onClick: closeModal, label: t('Save') }} cancelProps={{ onClick: closeModal }} />
    )
  }

  const getValue = (): string | undefined => {
    if (type === 'textArea') return value
    if (type === 'multiSelect') return values?.map((val) => val.label).join(' - ')
  }

  return (
    <React.Fragment>
      <Panel
        header={{
          title,
          actions: (
            <Button
              size="small"
              className={Styles.editButton}
              variant="secondary"
              onClick={(): void => setShowEditor(true)}
            >
              {t('Edit', { context: 'short' })}
            </Button>
          ),
        }}
      >
        <Section>
          <Value value={getValue()} />
        </Section>
      </Panel>

      <Modal
        show={showEditor}
        title={`Get connector - ${title} ${t('Edit').toLowerCase()}`}
        onClose={closeModal}
        footer={renderFooter()}
      >
        <ContentSection>
          {type === 'textArea' && (
            <TextArea id={id} label={title} value={textAreaValue || ''} onChange={setTextAreaValue} />
          )}

          {type === 'multiSelect' && (
            <MultiSelect
              id={id}
              label={title}
              options={options}
              selectedOptions={multiSelectValue || []}
              onChange={setMultiSelectValue}
            />
          )}
        </ContentSection>
      </Modal>
    </React.Fragment>
  )
}
