import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, StatusLabel, Typography } from '../../../../../Components'
import { ProgressBlock } from './Components/ProgressBlock'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ProgressInvitedEmployees: React.FC<IProps> = ({
  onInviteButtonClick,
  onResponseButtonClick,
  statistics,
  isDataDefinitive,
  hasOpenSurveys,
}): JSX.Element => {
  const { t } = useTranslation()
  const hasInvites = hasOpenSurveys

  const getRespondentsAndInvitees = (respondents: number | null, invitees: number | null): string | null => {
    if (!respondents && !invitees) return null

    return `${statistics.respondents}/${statistics.invitees}`
  }

  const getStatusLabel = (): JSX.Element | null => {
    switch (true) {
      case isDataDefinitive:
        return <StatusLabel variant="gray" label={t('Closed')} />
      case statistics.thresholdMet:
        return <StatusLabel variant="green" label={t('Goal met')} />
      case hasInvites:
        return <StatusLabel variant="orange" label={t('Invited')} />
      default:
        return null
    }
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <div className={Styles.header}>
          <Typography variant="h1" className={Styles.noMargin}>
            {t('Traveled kilometers employees')}
          </Typography>

          {getStatusLabel()}
        </div>

        {!hasInvites && (
          <Typography variant="body-2">{t('Invite employees to estimate their traveled kilometers.')}</Typography>
        )}

        {hasInvites && (
          <div className={Styles.progressBlocksWrapper}>
            <ProgressBlock
              title={t('Last invitation')}
              body={statistics.lastInvitation ? `${statistics.lastInvitation}` : ''}
            />

            {statistics.lastReminder && <ProgressBlock title={t('Last reminder')} body={statistics.lastReminder} />}

            <ProgressBlock title={t('Number invited')} body={statistics.invitees ? `${statistics.invitees}` : ''} />

            <ProgressBlock
              title={t('Number of respondents')}
              body={
                <div className={Styles.respondentsWrapper}>
                  <Typography
                    variant="body-2"
                    className={`${Styles.respondentsStatus} ${statistics.thresholdMet ? Styles.thresholdMet : ''} `}
                  >
                    {getRespondentsAndInvitees(statistics.respondents, statistics.invitees)}
                  </Typography>

                  <Typography variant="body-2" className={Styles.link} onClick={onResponseButtonClick}>
                    {t('Details_short')}
                  </Typography>
                </div>
              }
            />
          </div>
        )}
      </div>

      {!isDataDefinitive && (
        <Button size="large" variant="secondary" className={Styles.inviteButton} onClick={onInviteButtonClick}>
          {t('Invite employees')}
        </Button>
      )}
    </div>
  )
}
