import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Components'
import { ConfirmModal } from '../../../Components/ConfirmModal'
import { PageContainer } from '../../../Components/PageContainer'
import { PanelRow } from '../../../Components/PanelRow'
import { Section } from '../../../Components/Section'
import { Contact, Email, General, Settings } from './Components'

const CustomerLabelDetails = (): JSX.Element => {
  const { t } = useTranslation()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const labelName = 'Louwman'
  const clickHandler = (): void => setShowConfirm(true)

  const confirmHandler = (): void => {
    setShowConfirm(false)
    // ... other logic
  }

  return (
    <PageContainer
      header={{
        title: labelName,
        headerCustomComponent: (
          <Button size="large" variant="secondary" onClick={clickHandler}>
            {t('Delete', { context: 'short' })}
          </Button>
        ),
      }}
    >
      <Section title={t('Customer label')}>
        <PanelRow>
          <General />
          <Contact />
        </PanelRow>
      </Section>

      <Section title={t('Settings')}>
        <Settings />
        <Email />
      </Section>

      {showConfirm && (
        <ConfirmModal
          content={t('Are you sure you want to remove the {{ labelName }} customer label?', { labelName })}
          description={t('This action is irreversible.')}
          confirmButtonProps={{ onClick: confirmHandler, content: t('Yes, delete') }}
          onCancel={(): void => setShowConfirm(false)}
        />
      )}
    </PageContainer>
  )
}

export default CustomerLabelDetails
