import { Observable } from '@apollo/client'
import { GraphQLError } from 'graphql/error'

export const promiseToObservable = (promise: Promise<unknown>): Observable<unknown> =>
  new Observable((subscriber) => {
    promise
      .then(
        (value) => {
          if (subscriber.closed) return

          subscriber.next(value)
          subscriber.complete()
        },
        (err) => subscriber.error(err)
      )
      .catch(() => {})
  })

// @TODO: message has to be replaced with a statusCode when available
export const isUnauthorizedError = (errors: ReadonlyArray<GraphQLError> | undefined): boolean => {
  if (!errors) return false

  return !!errors.find((error) => error.message === 'Missing or invalid API token')
}
