import React from 'react'

import { Typography } from '../../../../../../../Components'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ProgressBlock: React.FC<IProps> = ({ title, body }): JSX.Element => {
  return (
    <div className={Styles.progressBlockWrapper}>
      <Typography variant="h2" className={Styles.noMargin}>
        {title}
      </Typography>

      {typeof body === 'string' ? <Typography variant="body-2">{body}</Typography> : body}
    </div>
  )
}
