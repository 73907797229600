import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../../../Components'
import { useTranslationDecorator } from '../../../../../../../Hooks/useTranslationDecorator'
import { formatDateString } from '../../../../../../../Lib/Formatters/DateFormatter'
import { IBudgetGroupWithSurvey } from '../../../../interfaces'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const InvitedBudgetGroups: React.FC<IProps> = ({ invitedBudgetGroups }): JSX.Element => {
  const { t } = useTranslation()
  const { locale } = useTranslationDecorator()

  return (
    <Fragment>
      {Object.keys(invitedBudgetGroups).map((key) => {
        const budgetGroupsInvited = invitedBudgetGroups[key]

        return (
          <Fragment key={`${key}-fragment`}>
            <div key={`${key}-header`} className={Styles.header}>
              <Typography variant="body-2" className={Styles.header}>
                {t('Employees below were invited at {{date}}:', {
                  date: formatDateString(new Date(key), 'd MMMM', locale),
                })}
              </Typography>
            </div>

            <div key={key} className={Styles.budgetGroupsWrapper}>
              {budgetGroupsInvited.map((budgetGroup: IBudgetGroupWithSurvey) => (
                <div key={budgetGroup.id} className={Styles.row}>
                  <div className={Styles.cell}>
                    <Typography variant="body-2">{budgetGroup.name}</Typography>
                  </div>

                  <div className={Styles.cell}>
                    <Typography variant="body-2">
                      {t('{{number}} employees', {
                        number: budgetGroup.employeeCount,
                      })}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </Fragment>
        )
      })}
    </Fragment>
  )
}
