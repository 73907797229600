import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../Components'
import { PageContainer } from '../../../Components/PageContainer'
import { ButtonFooter } from '../../../Components/PageContainer/Components/Footers/ButtonFooter'
import { RegularTable } from '../../../Components/RegularTable'
import { ROUTE_NAMES } from '../../../Layouts/Authorized/interfaces'
import { routes } from '../../../Layouts/Authorized/routes'

const OAuth = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<number>(1)

  const tableConfig = {
    name: {
      name: t('Name'),
      width: '300px',
    },
    contract: {
      name: t('Contract'),
      width: '300px',
    },
    scope: {
      name: t('Scope'),
      width: '424px',
    },
  }

  const tableData = [
    {
      id: '1',
      name: 'Leasefietsshop',
      contract: '-',
      scope: 'reporting',
      onClick: (): void => navigate('1'),
    },
    {
      id: '2',
      name: 'Leasefietsshop',
      contract: 'Reisbalans',
      scope: 'hr api_v2',
      onClick: (): void => navigate('2'),
    },
  ]

  const paginationChangeHandler = (page: number): void => {
    setCurrentPage(page)
  }

  return (
    <PageContainer
      header={{
        title: t('OAuth Applications'),
        showLine: true,
      }}
      footerContent={
        <ButtonFooter>
          <Button
            id="add-oauth"
            icon="add"
            size="large"
            onClick={(): void => navigate(routes[ROUTE_NAMES.OAUTH_APPLICATIONS_NEW])}
          >
            {t('Add application')}
          </Button>
        </ButtonFooter>
      }
    >
      <RegularTable
        tableProps={{ tableConfig, tableData }}
        paginationProps={{
          totalItemsPerPage: 10,
          totalItems: 13,
          selected: currentPage,
          onChange: paginationChangeHandler,
          navArrows: true,
          visiblePages: 10,
        }}
      />
    </PageContainer>
  )
}

export default OAuth
