import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Sheet: React.FC<IProps> = ({ children, hasPadding = true, isActive = false }) => {
  return (
    <div className={`${Styles.sheet} ${hasPadding ? Styles.hasPadding : ''} ${isActive ? Styles.isActive : ''}`}>
      {children}
    </div>
  )
}
