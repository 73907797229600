import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../Components'
import { PageContainer } from '../../Components/PageContainer'
import { LayoutContext } from '../../Providers/LayoutProvider'
import { DraggableContainer } from './Components/DraggableContainer'
import { TColumnData } from './Components/DraggableContainer/interfaces'
import { EditModal } from './Components/EditModal'
import { defaultWidgets, widgetStorageKey } from './helpers'
import Styles from './styles.module.scss'

export const Dashboard = (): JSX.Element => {
  const { t } = useTranslation()
  const { integrated } = useContext(LayoutContext)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [draggable, setDraggable] = useState<boolean>(false)
  const [containerData, setContainerData] = useState<TColumnData>({ LEFT: [], RIGHT: [] })

  const saveSortedWidgets = (input: TColumnData, isDraft = false): void => {
    setContainerData(input)
    if (!isDraft) window.localStorage.setItem(widgetStorageKey, JSON.stringify(input))
  }

  const initializeDefaultWidgets = useCallback(() => {
    saveSortedWidgets(defaultWidgets)
  }, [])

  const getStoredWidgets = (): TColumnData => JSON.parse(window.localStorage.getItem(widgetStorageKey) || '{}')

  const initializeWidgets = useCallback((): void => {
    const sortedWidgets = getStoredWidgets()
    if (Object.keys(sortedWidgets).length === 2) setContainerData(sortedWidgets)
    else initializeDefaultWidgets()
  }, [initializeDefaultWidgets])

  useEffect(() => {
    initializeWidgets()
  }, [initializeWidgets])

  const cancelHandler = (): void => {
    setDraggable(false)
    const sortedWidgets = getStoredWidgets()
    if (Object.keys(sortedWidgets).length === 2) setContainerData(sortedWidgets)
  }

  const saveHandler = (): void => {
    setDraggable(false)
    saveSortedWidgets(containerData)
  }

  const renderEditButton = (): ReactNode | null => {
    if (draggable) return null

    return (
      <Button id="edit" className={Styles.editButton} onClick={(): void => setDraggable((prev) => !prev)}>
        {t('Edit')}
      </Button>
    )
  }

  const renderFooter = (): JSX.Element | null => {
    if (!draggable) return null

    return (
      <div className={`${Styles.footer} ${integrated ? Styles.integrated : ''}`}>
        <Button variant="secondary" size="large" onClick={(): void => setShowEditModal(true)}>
          {t('Add/remove widgets')}
        </Button>

        <div className={Styles.buttonsWrapper}>
          <Button id="cancel" variant="secondary" onClick={cancelHandler} size="large">
            {t('Cancel')}
          </Button>
          <Button id="save" onClick={saveHandler} size="large">
            {t('Save')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <PageContainer
      header={{
        title: t('Dashboard'),
        showLine: false,
        headerCustomComponent: renderEditButton(),
      }}
      footerContent={renderFooter()}
    >
      {/* <NewTermsModal /> */}
      <DraggableContainer items={containerData} onChange={setContainerData} disabled={!draggable} />

      {showEditModal && (
        <EditModal
          items={containerData}
          onSave={(input): void => saveSortedWidgets(input, true)}
          onClose={(): void => setShowEditModal(false)}
        />
      )}
    </PageContainer>
  )
}
