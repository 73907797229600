import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../Button'
import { ButtonFooter } from '../ButtonFooter'
import { IProps } from './interfaces'

export const ConfirmFooter: React.FC<IProps> = ({
  onConfirm,
  onCancel,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonIcon,
  disabled = false,
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ButtonFooter justify="space-between">
      <Button data-testid="cancel" onClick={onCancel} size="large">
        {cancelButtonLabel || t('Cancel')}
      </Button>
      <Button icon={confirmButtonIcon} data-testid="confirm" onClick={onConfirm} disabled={disabled} size="large">
        {confirmButtonLabel || t('Confirm')}
      </Button>
    </ButtonFooter>
  )
}
