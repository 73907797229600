import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../Components'
import { LimitedListBadge } from '../../../Components/LimitedListBadge'
import { PageContainer } from '../../../Components/PageContainer'
import { ButtonFooter } from '../../../Components/PageContainer/Components/Footers/ButtonFooter'
import { RegularTable } from '../../../Components/RegularTable'
import { ROUTE_NAMES } from '../../../Layouts/Authorized/interfaces'
import { routes } from '../../../Layouts/Authorized/routes'
import { publishedTo, tableConfig } from './helpers'

const GetConnectors = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<number>(1)

  const limit = 15
  const tableData = [
    {
      id: '1',
      name: 'Get connector 1',
      organisations: <LimitedListBadge list={publishedTo} limit={limit} />,
      onClick: (): void => navigate('1'),
    },
    {
      id: '2',
      name: 'Get connector 2',
      organisations: <LimitedListBadge list={publishedTo} limit={limit} />,
      onClick: (): void => navigate('2'),
    },
    {
      id: '3',
      name: 'Get connector 3',
      organisations: <LimitedListBadge list={['Reisbalans B.V.']} limit={5} />,
      onClick: (): void => navigate('3'),
    },
  ]

  const paginationChangeHandler = (page: number): void => {
    setCurrentPage(page)
  }

  return (
    <PageContainer
      header={{
        title: 'Get connectors',
        showLine: true,
      }}
      footerContent={
        <ButtonFooter>
          <Button
            id="add-oauth"
            icon="add"
            size="large"
            onClick={(): void => navigate(routes[ROUTE_NAMES.GET_CONNECTORS_NEW])}
          >
            {t('Add GetConnector')}
          </Button>
        </ButtonFooter>
      }
    >
      <RegularTable
        tableProps={{ tableConfig, tableData }}
        paginationProps={{
          totalItemsPerPage: 10,
          totalItems: 13,
          selected: currentPage,
          onChange: paginationChangeHandler,
          navArrows: true,
          visiblePages: 10,
        }}
      />
    </PageContainer>
  )
}

export default GetConnectors
