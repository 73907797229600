import React from 'react'

import { Badge } from '../../../../../../Badge'
import { Button } from '../../../../../../Button'
import { Icon } from '../../../../../../Icon'
import { Typography } from '../../../../../../Typography'
import { IProps } from './interface'
import Styles from './styles.module.scss'

export const ListItem: React.FC<IProps> = ({ children, count, disabled, onClick, icon }): JSX.Element => {
  const className = ((): string => {
    const classes: string[] = [Styles.listItem]

    if (disabled) classes.push(Styles.disabled)
    if (onClick) classes.push(Styles.hasButton)

    return classes.join(' ')
  })()

  return (
    <li className={className} data-testid="list-item">
      <div
        className={Styles.innerContainer}
        onClick={(): void => {
          if (onClick) onClick()
        }}
      >
        <div className={Styles.labelContainer}>
          {icon && <Icon name={icon} className={Styles.icon} />}
          <Typography variant="h1" element="span" className={Styles.label}>
            {children}
          </Typography>
        </div>
        {count !== undefined && <Badge>{count}</Badge>}
        {onClick && <Button className={Styles.button} disabled={disabled} variant="secondary" icon="chevron-right" />}
      </div>
    </li>
  )
}
