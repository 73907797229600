import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../Components'
import { List } from '../../../../Components/Panel/Components/Content/List'
import { Typography } from '../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ListRow: React.FC<IProps> = ({ title, date, time, onClick }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <List>
      <div className={Styles.wrapper}>
        <Typography variant="h2" className={Styles.title}>
          {title}
        </Typography>
        <Typography variant="body-2" className={Styles.date}>
          {date}
        </Typography>
        <Typography variant="body-2" className={Styles.time}>
          {time}
        </Typography>
        <Button
          id="download-button"
          onClick={onClick}
          variant="secondary"
          className={Styles.button}
          icon="download"
          size="small"
        >
          {t('Download')}
        </Button>
      </div>
    </List>
  )
}
