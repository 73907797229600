import './base.scss'
import 'react-toastify/dist/ReactToastify.css'
import './Lib/i18n'
import 'react-loading-skeleton/dist/skeleton.css'

import React, { useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { ErrorFallback } from './Components/ErrorFallback'
import { SideDrawerContainer } from './Components/SideDrawerContainer'
import { AuthorizedLayout } from './Layouts/Authorized'
import { UnauthorizedLayout } from './Layouts/Unauthorized'
import { AuthContext, AuthProvider } from './Providers/AuthProvider'
import { LocaleProvider } from './Providers/LocaleProvider'
import { UserPreferencesProvider } from './Providers/UserPreferencesProvider'

const SelectLayout = (): JSX.Element => {
  const { loggedIn } = useContext(AuthContext)
  return loggedIn ? <AuthorizedLayout /> : <UnauthorizedLayout />
}

export const App = (): JSX.Element => {
  // Enable dark / light themes according to system preferences
  // const theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  // document.documentElement.setAttribute('data-theme', theme)

  return (
    <Router>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ToastContainer position="bottom-right" newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable />
        <LocaleProvider>
          <AuthProvider>
            <UserPreferencesProvider>
              <SelectLayout />
              <SideDrawerContainer id="sideDrawerContainer" />
            </UserPreferencesProvider>
          </AuthProvider>
        </LocaleProvider>
      </ErrorBoundary>
    </Router>
  )
}
