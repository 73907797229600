import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../../../Components'
import { FileUpload } from '../../../../../../../Components/FormComponents/FileUpload'
import { IFile } from '../../../../../../../Components/FormComponents/FileUpload/interfaces'
import { Form } from '../../../../../../../Components/FormComponents/Form'
import { InlineFields } from '../../../../../../../Components/FormComponents/InlineFields'
import { Input } from '../../../../../../../Components/FormComponents/Input'
import { Radio } from '../../../../../../../Components/FormComponents/Radio'
import { Option } from '../../../../../../../Components/FormComponents/Radio/Components/Option'
import { TextArea } from '../../../../../../../Components/FormComponents/TextArea'
import { Modal } from '../../../../../../../Components/Modal'
import { ConfirmFooter, ContentSection } from '../../../../../../../Components/Modal/Components'
import Styles from './styles.module.scss'

export const Edit = (): JSX.Element => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const [template, setTemplate] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [sendFrom, setSendFrom] = useState<string>('')
  const [salutation, setSalutation] = useState<string>('')
  const [closure, setClosure] = useState<string>('')
  const [headerImage, setHeaderImage] = useState<IFile | null>(null)

  const clickHandler = (): void => setShow(true)
  const closeHandler = (): void => setShow(false)

  const renderFooter = (): JSX.Element => {
    return (
      <ConfirmFooter
        cancelProps={{ onClick: closeHandler }}
        confirmProps={{ label: t('Save'), onClick: closeHandler }}
      />
    )
  }

  const formType = t('Change email')
  const size = { width: 200, height: 200 }

  return (
    <React.Fragment>
      <Button variant="secondary" size="small" onClick={clickHandler}>
        {t('Edit', { context: 'short' })}
      </Button>

      {show && (
        <Modal
          title={t('Customer label - {{ formType }}', { formType })}
          onClose={closeHandler}
          footer={renderFooter()}
          show
        >
          <ContentSection>
            <Form>
              <Radio label={t('Admin')} id="admin" onChange={(value): void => setIsAdmin(value === 'true')}>
                <Option label={t('Yes')} value="true" selected={isAdmin} />
                <Option label={t('No')} value="false" selected={!isAdmin} />
              </Radio>

              <Input
                id="template"
                wrapperClassName={Styles.input}
                label={t('Template')}
                value={template}
                onChange={setTemplate}
              />

              <InlineFields>
                <Input id="email" label={t('E-mail address')} value={email} onChange={setEmail} />

                <Input id="phoneNumber" label={t('Phone number')} value={phoneNumber} onChange={setPhoneNumber} />
              </InlineFields>

              <Input
                id="sendFrom"
                wrapperClassName={Styles.input}
                label={t('Send from')}
                value={sendFrom}
                onChange={setSendFrom}
              />

              <TextArea
                id="salutation"
                label={t('Salutation')}
                placeholder={t('Standard salutation in emails')}
                value={salutation}
                onChange={setSalutation}
              />

              <TextArea
                id="closure"
                label={t('Closure')}
                placeholder={t('Standard closure in emails')}
                value={closure}
                onChange={setClosure}
              />

              <FileUpload
                type="image"
                label={t('Header image')}
                value={headerImage}
                onChange={setHeaderImage}
                size={size}
              />
            </Form>
          </ContentSection>
        </Modal>
      )}
    </React.Fragment>
  )
}
