import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Components'
import { PageContainer } from '../../../Components/PageContainer'
import { Table } from '../../../Components/Table'
import Styles from './styles.module.scss'

export const TermsConditionsPrevious: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const columnWidths = [248, 248, 528]

  const tableConfig = {
    contract: {
      name: t('Contract'),
      width: `${columnWidths[0]}px`,
    },
    startDate: {
      name: t('Starting date'),
      width: `${columnWidths[1]}px`,
    },
    endDate: {
      name: t('End date'),
      width: `${columnWidths[2]}px`,
    },
  }

  const tableData = [
    {
      id: '1',
      contract: 'Freddi',
      startDate: '08-12-2023, 05:14',
      endDate: (
        <div className={Styles.buttonWrapper}>
          <div>12-12-2023, 05:14</div>
          <Button className={Styles.button} icon="download" variant="secondary" size="small">
            DOWNLOAD
          </Button>
        </div>
      ),
    },
    {
      id: '2',
      contract: 'Freddi',
      startDate: '08-12-2023, 05:14',
      endDate: '12-12-2023, 05:14',
    },
  ]

  return (
    <PageContainer
      header={{
        title: t('Terms of use - previous'),
        showLine: true,
      }}
    >
      <Table tableConfig={tableConfig} tableData={tableData} />
    </PageContainer>
  )
}
