import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Button'
import { Icon } from '../../../../../Icon'
import { Typography } from '../../../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const TableViewOption: React.FC<IProps> = ({ isActive, onApply, onDelete, name, variants }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div data-testid={`option-set-${name}`} className={Styles.option}>
      <div className={Styles.row}>
        <Icon className={Styles.icon} name="list" />
        <div className={Styles.context}>
          <Typography className={Styles.name} variant="h1">
            {name}
          </Typography>

          <div className={Styles.buttons}>
            {onDelete && (
              <Button className={Styles.delete} size="small" variant="secondary" onClick={onDelete}>
                {t('Delete', { context: 'short' })}
              </Button>
            )}
            {!isActive && (
              <Button className={Styles.apply} size="small" onClick={onApply}>
                {t('Use')}
              </Button>
            )}
          </div>
        </div>
      </div>

      <Typography className={Styles.variants} variant="body-3">
        {`${variants[0].toUpperCase()}${variants.slice(1).toLowerCase()}`}
      </Typography>
    </div>
  )
}
