import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../../../Components'
import { Input } from '../../../../../../../Components/FormComponents/Input'
import { Icon } from '../../../../../../../Components/Icon'
import { ITravelPurposeEnum, IWrpmModalityEnum } from '../../../../../../../Lib/graphql'
import { mapModalityEnumToIconName } from '../../../../helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ManualEditForm: React.FC<IProps> = ({ data, onChange }): JSX.Element | null => {
  const { t } = useTranslation()

  if (!data) return null

  return (
    <div>
      {data.map(({ modality, statsPerModalityCommute, statsPerModalityBusiness }, index) => (
        <div key={index} className={Styles.container}>
          <div className={Styles.header}>
            <div className={Styles.icon}>
              <Icon name={mapModalityEnumToIconName(modality?.value as IWrpmModalityEnum)} />
            </div>

            <Typography variant="h2">{modality?.humanized}</Typography>
          </div>

          <div className={Styles.fields}>
            <Input
              id={`${modality?.value}-commute`}
              label={t('Commute')}
              value={statsPerModalityCommute?.totalKms}
              clearable={false}
              onChange={(value): void =>
                onChange(modality?.value as IWrpmModalityEnum, ITravelPurposeEnum.COMMUTE, value)
              }
            />

            <Input
              id={`${modality?.value}-business`}
              label={t('Business')}
              value={statsPerModalityBusiness?.totalKms}
              clearable={false}
              onChange={(value): void =>
                onChange(modality?.value as IWrpmModalityEnum, ITravelPurposeEnum.BUSINESS, value)
              }
            />
          </div>
        </div>
      ))}
    </div>
  )
}
