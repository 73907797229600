import React, { useEffect, useMemo, useState } from 'react'

import { MainLoading } from '../../Components/MainLoading'
import { MaintenanceNotice } from '../../Components/MaintenanceNotice'
import { useGetThemeLazyQuery } from '../../Lib/graphql'
import { setCSSColorVarsForColors } from './helpers'
import { IThemeContext, TTheme } from './interfaces'

export const ThemeContext = React.createContext<IThemeContext>({
  currentTheme: {},
  setOverrideHostname: (): void => {},
})

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element | null => {
  const [loadThemeForHost, { data, loading, error }] = useGetThemeLazyQuery()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [theme, setTheme] = useState<TTheme>({})
  const [overrideHostname, setOverrideHostname] = useState<string>()

  const hostname = useMemo(() => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const queryParams = url.searchParams
    queryParams.get('hostname')

    return overrideHostname || queryParams.get('hostname') || window.location.hostname
  }, [overrideHostname])

  useEffect(() => {
    loadThemeForHost({
      variables: {
        hostname,
      },
    })
  }, [loadThemeForHost, overrideHostname, hostname])

  // Set initial fallback
  useEffect(() => {
    setCSSColorVarsForColors({ primaryColor: theme?.primaryColor, secondaryColor: theme?.secondaryColor })
  }, [theme])

  // This useEffect hook below processes the CSS when we received a response from the backend
  useEffect(() => {
    if (!data) return
    if (!initialized) setInitialized(true)
    if (!data?.reisbalans?.configurationProfileForHost?.customerLabelTheme) return
    setTheme(data.reisbalans.configurationProfileForHost.customerLabelTheme)
  }, [data, initialized])

  useEffect(() => {
    let timeout: number

    if (error) {
      timeout = window.setTimeout((): void => {
        window.location.reload()
      }, 10000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [error])

  const options = useMemo(() => {
    return { currentTheme: theme || {}, overrideHostname, setOverrideHostname }
  }, [overrideHostname, theme])

  if (error) return <MaintenanceNotice error={error} />
  if (!initialized || loading) return <MainLoading />
  return <ThemeContext.Provider value={options}>{children}</ThemeContext.Provider>
}
