import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Typography } from '../../../Components'
import Styles from '../styles.module.scss'

export const PageNotFound: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={Styles.container}>
      <div className={Styles.imgContainer} />

      <Typography variant="h1-large" className={Styles.title}>
        {t('Page not found')}
      </Typography>

      <Typography variant="body-2" className={Styles.errorMessage}>
        {t(
          "The page you were looking for could not be found. It may have been deleted, renamed, or didn't exist in the first place. Return to your dashboard to continue."
        )}
      </Typography>

      <Button className={Styles.button} variant="primary" onClick={(): void => navigate('/')}>
        {t('Go to my dashboard')}
      </Button>
    </div>
  )
}

export default PageNotFound
