import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Typography } from '../../../Components'
import { ROUTE_NAMES } from '../../../Layouts/Unauthorized/interfaces'
import { routes } from '../../../Layouts/Unauthorized/routes'
import Styles from '../styles.module.scss'

export const SessionExpiredPage: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={Styles.container}>
      <div className={Styles.imgContainer} />

      <Typography variant="h1-large" className={Styles.title}>
        {t('Your session has expired.')}
      </Typography>

      <Typography variant="body-2" className={Styles.errorMessage}>
        {t(
          'Your session has expired and you have been logged out. To continue using the platform, you will need to login again.'
        )}
      </Typography>

      <Button className={Styles.button} variant="primary" onClick={() => navigate(routes[ROUTE_NAMES.LOGIN])}>
        {t('Back to login')}
      </Button>
    </div>
  )
}

export default SessionExpiredPage
