import React from 'react'

import { Icon } from '../Icon'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Button: React.FC<IProps> = ({ variant, size, type, className = '', ...otherProps }): JSX.Element => {
  const { fullWidth = false } = otherProps
  const classNames = `${Styles.button} ${Styles[variant ?? 'primary']} ${Styles[size ?? 'medium']} ${
    fullWidth ? Styles.fullWidth : ''
  } ${className}`

  const renderLabel = (): React.ReactNode => {
    const { children, icon } = otherProps

    if (!icon) return children

    const { iconPosition = 'before' } = otherProps
    const iconClassNames = `${Styles.icon} ${Styles[iconPosition]}`

    return (
      <span className={Styles.label}>
        <Icon name={icon} color="var(--color)" className={iconClassNames} data-testid={`button-icon-${icon}`} />

        {children}
      </span>
    )
  }

  return (
    <button type={type === 'submit' ? 'submit' : 'button'} className={classNames} {...otherProps}>
      {renderLabel()}
    </button>
  )
}
