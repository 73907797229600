import React from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore as there is no type definition file anymore.
import ReactSelect, {
  ActionMeta,
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  SingleValue,
  StylesConfig,
} from 'react-select'

import { MaybeRenderWithLabelHOC } from '../../../Lib/MaybeRenderWithLabelHOC'
import { truncateString } from '../../../Lib/sanitizers'
import colors from '../../../Theme/colors.module.scss'
import fonts from '../../../Theme/fonts.module.scss'
import { Icon } from '../../Icon'
import { selectStyle } from './helpers'
import { IProps } from './interfaces'

export const SelectComponent = <T,>(props: IProps<T>): JSX.Element => {
  const {
    id,
    disabled,
    onChange,
    placeholder,
    label,
    value,
    errors,
    ariaLabel,
    small,
    menuPlacement = 'bottom',
    menuAlignment = 'left',
    ...otherProps
  } = props
  const { t } = useTranslation()

  const baseStyles: StylesConfig<T, false> = {
    container: (provided) => {
      return {
        ...provided,
        marginTop: label ? '12px !important' : '0',
        marginBottom: errors?.length ? '0.5em' : '0',
        width: small ? '164px' : '100%',
        fontFamily: fonts.fontFamily,
      }
    },
    singleValue: (provided) => {
      return {
        ...provided,
        lineHeight: 'normal',
      }
    },
    control: (provided: CSSObjectWithLabel) => {
      const borderColor = ((): string => {
        if (errors?.length) return colors.red

        return colors.gray200
      })()

      return {
        ...provided,
        gap: '8px',
        padding: '3px 12px',
        boxSizing: 'content-box',
        height: '40px',
        boxShadow: 'none',
        borderColor,
        '&:hover': {
          borderColor,
          boxShadow: 'none',
          cursor: 'pointer',
        },
        '&:focus': {
          borderColor,
          boxShadow: 'none',
        },
      }
    },
    menu: (provided) => {
      return {
        ...provided,
        zIndex: 2,
        boxShadow: '0px 2px 8px rgba(173, 181, 189, 0.5)',
        minWidth: '288px',
        right: menuAlignment === 'right' ? '0' : 'none',
      }
    },
  }

  const handleOnChange = (newValue: SingleValue<T>, _actionMeta: ActionMeta<T>): void => {
    if (onChange) onChange(newValue)
  }

  const placeholderText = (): string => {
    if (small) return truncateString(placeholder || t('Please select an option'), 12)
    return placeholder || t('Please select an option')
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const DropdownIndicator = (elements: DropdownIndicatorProps<T, false, GroupBase<T>>): JSX.Element => {
    return (
      <components.DropdownIndicator {...elements}>
        <Icon name="chevron-down" width={12} height={12} />
      </components.DropdownIndicator>
    )
  }

  return (
    <ReactSelect<T>
      {...otherProps}
      aria-label={ariaLabel}
      classNamePrefix={id}
      isDisabled={disabled}
      inputId={id}
      noOptionsMessage={(_obj: { inputValue: string }): string | null => t('no results found...')}
      onChange={handleOnChange}
      placeholder={placeholderText()}
      styles={{ ...(selectStyle as StylesConfig<T>), ...baseStyles }}
      value={value}
      isMulti={false}
      isSearchable={false}
      menuPlacement={menuPlacement}
      components={{ DropdownIndicator }}
    />
  )
}

export const Select = MaybeRenderWithLabelHOC(SelectComponent) as <T>(props: IProps<T>) => JSX.Element
