import { IGetWrpmQuery, ITravelPurposeEnum, IUpdateInput } from '../../../../../Lib/graphql'

// transform the wrpm data which has two values per modality to an array with two items per modality based on travel purpose
export const transformData = (data: IGetWrpmQuery['reisbalansV2']['wrpm']): IUpdateInput['surveyKms'] => {
  if (!data) return []

  return data.reduce(
    (acc, value) => {
      if (value.modality?.value) {
        acc!.push({
          kms: parseInt(value.statsPerModalityCommute?.totalKms, 10) || 0,
          travelPurpose: ITravelPurposeEnum.COMMUTE,
          modality: value.modality?.value,
        })

        acc!.push({
          kms: parseInt(value.statsPerModalityBusiness?.totalKms, 10) || 0,
          travelPurpose: ITravelPurposeEnum.BUSINESS,
          modality: value.modality?.value,
        })
      }

      return acc
    },
    [] as IUpdateInput['surveyKms']
  )
}
