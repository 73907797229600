import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
  InMemoryCacheConfig,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import ApolloLinkNetworkStatus from 'react-apollo-network-status/dist/src/ApolloLinkNetworkStatus'

import { generateApolloClientHeaders } from '../headersHelper'
import { TLocale } from '../sharedInterfaces'
import { ICustomResponseObject } from './apolloClientInterfaces'
import { isUnauthorizedError, promiseToObservable } from './apolloHelpers'

// We add the operation name as query parameter so that the VCR functionality can differentiate between consecutive graphql requests.
// As an added bonus, this also allows us to track statistics on how ofter a certain query is called.
const customFetch = (uri: string, options: RequestInit | undefined): Promise<Response> => {
  const { operationName } = JSON.parse((options?.body as string) || '')
  return fetch(`${uri}?operationName=${operationName}`, options)
}

const httpLink = createHttpLink({
  uri: import.meta.env.REACT_APP_GRAPHQL_URL,
  fetch: customFetch,
  ...(import.meta.env.MODE === 'production' && { credentials: 'include' }),
})

// Generate shared InMemoryCache options to share between this apollo client and mockedprovider
export const sharedInMemoryCacheOptions: InMemoryCacheConfig = {
  dataIdFromObject(responseObject: ICustomResponseObject) {
    // eslint-disable-next-line sonarjs/no-small-switch
    let id: string | undefined = 'GooglePlaces'
    switch (responseObject.__typename) {
      case 'Coordinates':
        return `Cooridantes:${responseObject.latitude}-${responseObject.longitude}`

      case 'GooglePlaces':
        if (responseObject.suggest?.placeId) id = `suggest_${responseObject.suggest.placeId}`
        if (responseObject.details?.placeId) id = `details_${responseObject.details.placeId}`

        return `GooglePlaces:${id}`

      default:
        id = defaultDataIdFromObject(responseObject)
        if (!id) {
          if (responseObject.create?.taxiReservation)
            return `TaxiReservation:${responseObject.create.taxiReservation.id || 'UNKNOWN'}`

          if (responseObject.permissions && responseObject.id) return `Permissions:${responseObject.id}`
        }
        return defaultDataIdFromObject(responseObject)
    }
  },
}

const unauthorizedLink = (locale: TLocale): ReturnType<typeof setContext> =>
  setContext((_request, context) => {
    return {
      headers: {
        ...context.headers,
        ...generateApolloClientHeaders({ withAuthorization: false, locale }),
      },
    }
  })

const authorizedLink = (accessToken: string | null, locale: TLocale): ReturnType<typeof setContext> =>
  setContext((request, context) => {
    return {
      headers: {
        ...context.headers,
        ...generateApolloClientHeaders({ accessToken, withAuthorization: true, role: context.role, locale }),
      },
    }
  })

const errorLinkAuthorized = (onUnauthorizedError: () => Promise<string>): ApolloLink =>
  onError(({ operation, forward, response }) => {
    if (isUnauthorizedError(response?.errors))
      return promiseToObservable(onUnauthorizedError()).flatMap(() => forward(operation))
  })

const errorLinkUnauthorized = (onReAuthorizeError: () => void): ApolloLink =>
  onError(({ response }) => {
    if (isUnauthorizedError(response?.errors)) onReAuthorizeError()
  })

const generateClient = (
  receivedLink: ReturnType<typeof setContext>,
  link?: ApolloLinkNetworkStatus
): InstanceType<typeof ApolloClient> => {
  return new ApolloClient({
    cache: new InMemoryCache(sharedInMemoryCacheOptions),
    link: link ? link.concat(receivedLink.concat(httpLink)) : receivedLink.concat(httpLink),
    connectToDevTools: true,
  })
}

export const authorizedClient = (
  accessToken: string | null,
  link: ApolloLinkNetworkStatus,
  locale: TLocale,
  onUnauthorizedError = (): Promise<string> => Promise.resolve('')
): ApolloClient<unknown> =>
  generateClient(authorizedLink(accessToken, locale).concat(errorLinkAuthorized(onUnauthorizedError)), link)

export const unauthorizedClient = (
  locale: TLocale,
  onReAuthorizeError: () => void = (): void => {}
): ApolloClient<unknown> => generateClient(unauthorizedLink(locale).concat(errorLinkUnauthorized(onReAuthorizeError)))
