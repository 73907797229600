import { ApolloProvider } from '@apollo/client'
import React, { Suspense, useContext } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { Route, Routes, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { authorizedClient } from '../../Lib/Apollo/apolloClient'
import { CreateCustomerLabel } from '../../Pages/CustomerLabels/Create'
import CustomerLabelDetails from '../../Pages/CustomerLabels/Details'
import { CustomerLabels } from '../../Pages/CustomerLabels/Overview'
import { Dashboard } from '../../Pages/Dashboard'
import { DrivenLeaseCarFleet } from '../../Pages/DrivenLeaseContracts'
import PageNotFound from '../../Pages/ErrorPages/404'
import { FeatureFlags } from '../../Pages/FeatureFlags/Overview'
import GetConnectorDetails from '../../Pages/GetConnectors/Details'
import GetConnectorsNew from '../../Pages/GetConnectors/New'
import GetConnectors from '../../Pages/GetConnectors/Overview'
import { MessagesDetails } from '../../Pages/Messages/Details'
import NewMessage from '../../Pages/Messages/New'
import { Messages } from '../../Pages/Messages/Overview'
import OAuthDetails from '../../Pages/OAuth/Details'
import OAuthNew from '../../Pages/OAuth/New'
import OAuth from '../../Pages/OAuth/Overview'
import PoolCars from '../../Pages/PoolCars/Overview'
import { TermsConditions } from '../../Pages/TermsConditions'
import { TermsConditionsPrevious } from '../../Pages/TermsConditions/Previous'
import { WRPMOverview } from '../../Pages/WRPM/Overview'
import { AuthContext } from '../../Providers/AuthProvider'
import { LayoutContext, LayoutProvider } from '../../Providers/LayoutProvider'
import { LocaleContext } from '../../Providers/LocaleProvider'
import { ThemeProvider } from '../../Providers/ThemeProvider'
import { DrivenLayout } from '../Driven'
import { IntegratedLayout } from '../Integrated'
import { ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const Content = (): JSX.Element => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path={routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS]} element={<TermsConditionsPrevious />} />
        <Route path={routes[ROUTE_NAMES.DASHBOARD]} element={<Dashboard />} />

        <Route
          path={routes[ROUTE_NAMES.DRIVEN_LEASE_CONTRACTS]}
          element={<DrivenLeaseCarFleet activeTabId="carContracts" />}
        />

        {/* TODO: Remove route below when backend url has been changed to new one DRIVEN_LEASE_CONTRACTS */}
        <Route
          path={routes[ROUTE_NAMES.DRIVEN_LEASE_CAR_FLEET]}
          element={<DrivenLeaseCarFleet activeTabId="carContracts" />}
        />

        <Route path={routes[ROUTE_NAMES.CUSTOMER_LABELS_DETAILS]} element={<CustomerLabelDetails />} />
        <Route path={routes[ROUTE_NAMES.CUSTOMER_LABELS]} element={<CustomerLabels />} />
        <Route path={routes[ROUTE_NAMES.CUSTOMER_LABELS_CREATE]} element={<CreateCustomerLabel />} />
        <Route path={routes[ROUTE_NAMES.WRPM_OVERVIEW]} element={<WRPMOverview />} />
        <Route path={routes[ROUTE_NAMES.MESSAGES]} element={<Messages />} />
        <Route path={routes[ROUTE_NAMES.MESSAGES_DETAILS]} element={<MessagesDetails />} />
        <Route path={routes[ROUTE_NAMES.MESSAGES_NEW]} element={<NewMessage />} />
        <Route path={routes[ROUTE_NAMES.GET_CONNECTORS]} element={<GetConnectors />} />
        <Route path={routes[ROUTE_NAMES.GET_CONNECTORS_DETAILS]} element={<GetConnectorDetails />} />
        <Route path={routes[ROUTE_NAMES.GET_CONNECTORS_NEW]} element={<GetConnectorsNew />} />

        <Route path={routes[ROUTE_NAMES.OAUTH_APPLICATIONS]} element={<OAuth />} />
        <Route path={routes[ROUTE_NAMES.OAUTH_APPLICATIONS_NEW]} element={<OAuthNew />} />
        <Route path={routes[ROUTE_NAMES.OAUTH_APPLICATIONS_DETAILS]} element={<OAuthDetails />} />

        <Route path={routes[ROUTE_NAMES.FEATURE_FLAGS]} element={<FeatureFlags />} />
        <Route path={routes[ROUTE_NAMES.POOL_CARS]} element={<PoolCars />} />

        <Route path={routes[ROUTE_NAMES.TERMS_CONDITIONS]} element={<TermsConditions />} />

        {/* Keep as the last path! */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  )
}

const Router: React.FC = (): JSX.Element => {
  const location = useLocation()
  const { integrated } = useContext(LayoutContext)

  return (
    <TransitionGroup className={Styles.appRoutesTransitionGroup}>
      <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} className="fade">
        {integrated ? <IntegratedLayout>{Content()}</IntegratedLayout> : <DrivenLayout>{Content()}</DrivenLayout>}
      </CSSTransition>
    </TransitionGroup>
  )
}

export const AuthorizedLayout: React.FC = (): JSX.Element => {
  const { locale } = useContext(LocaleContext)
  const { accessToken, reauthorizeWithRefreshToken } = useContext(AuthContext)
  const { link } = createNetworkStatusNotifier()

  return (
    <ApolloProvider client={authorizedClient(accessToken, link, locale, reauthorizeWithRefreshToken)}>
      <ThemeProvider>
        <LayoutProvider>
          <Router />
        </LayoutProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}
