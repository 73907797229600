import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Components'
import { Modal } from '../../../../../Components/Modal'
import { PoolCarForm } from '../PoolCarForm'

export const NewPoolCar = (): JSX.Element => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  const handleClose = (): void => setShow(false)

  return (
    <React.Fragment>
      <Button icon="add" size="large" onClick={(): void => setShow(true)}>
        {t('Add pool car')}
      </Button>
      <Modal title={t('Add pool car')} show={show} onClose={handleClose}>
        <PoolCarForm onClose={handleClose} />
      </Modal>
    </React.Fragment>
  )
}
