import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../Button'
import { ModalContainer } from '../ModalContainer'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ConfirmModal: React.FC<IProps> = ({
  content,
  description,
  cancelContent,
  onCancel,
  confirmButtonProps: { content: buttonContent, ...restButtonProps },
  children,
}): JSX.Element => {
  const { t } = useTranslation()
  const cancelButton = cancelContent || t('Cancel')

  const contentClasses: string[] = [Styles.content]
  if (!description) contentClasses.push(Styles.fullPadding)

  return (
    <ModalContainer
      withPadding
      id="confirm-modal"
      title={t('Confirm')}
      className={Styles.modal}
      onClose={onCancel}
      show
    >
      <Typography className={contentClasses.join(' ')} variant="h1">
        {content}
      </Typography>

      {description && (
        <Typography className={Styles.description} variant="body-2">
          {description}
        </Typography>
      )}

      <div className={Styles.wrapper}>
        {children}

        <Button
          {...restButtonProps}
          id="confirm-button"
          onClick={(mouseEvent): void => {
            mouseEvent.stopPropagation()
            restButtonProps.onClick()
          }}
          size="large"
        >
          {buttonContent}
        </Button>

        <Button
          id="cancel-button"
          onClick={(event): void => {
            event.stopPropagation()
            onCancel()
          }}
          variant="secondary"
          size="large"
        >
          {cancelButton}
        </Button>
      </div>
    </ModalContainer>
  )
}
