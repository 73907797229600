import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { useNavigate } from 'react-router-dom'

import { ROUTE_NAMES } from '../../Layouts/Unauthorized/interfaces'
import { routes } from '../../Layouts/Unauthorized/routes'
import { authorizedClient, unauthorizedClient } from '../../Lib/Apollo/apolloClient'
import { AdminLoginDocument, LogoutDocument, ReauthorizeDocument } from '../../Lib/graphql'
import { LocaleContext } from '../LocaleProvider'
import { IAuthContext, TRedirect } from './interfaces'

export const AuthContext = React.createContext<IAuthContext>({
  login: (_email, _password, _otp, _remember, _redirect): Promise<boolean> => Promise.resolve(false),
  logout: (): void => {},
  reauthorizeWithToken: (_token: string): Promise<string> => Promise.resolve(''),
  reauthorizeWithRefreshToken: (): Promise<string> => Promise.resolve(''),
  accessToken: '',
  loggedIn: false,
})

const storageAccessTokenKey = 'accessToken'
const storageRefreshTokenKey = 'refreshToken'
let isRefreshingToken = false

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (props): JSX.Element => {
  const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem(storageAccessTokenKey))
  const [loggedIn, setLoggedIn] = useState<boolean>(!!accessToken)

  const { children } = props
  const { locale } = useContext(LocaleContext)
  const navigate = useNavigate()

  // This needs to be here to make sure no old tokens remain in storage while switching to the new storage keys
  useEffect(() => {
    localStorage.removeItem('jwtToken')
    sessionStorage.removeItem('jwtToken')
  }, [])

  useEffect(() => {
    const storedAccessToken =
      localStorage.getItem(storageAccessTokenKey) || sessionStorage.getItem(storageAccessTokenKey)

    if (storedAccessToken) {
      setAccessToken(storedAccessToken)
      setLoggedIn(true)
    }
  }, [])

  const clearTokensFromStorage = (): void => {
    localStorage.removeItem(storageAccessTokenKey)
    sessionStorage.removeItem(storageAccessTokenKey)

    localStorage.removeItem(storageRefreshTokenKey)
    sessionStorage.removeItem(storageRefreshTokenKey)
  }

  const storeRedirectInformation = useCallback((redirect: TRedirect): void => {
    // The redirectPath is stored in the sessionStorage because otherwise it is not accessible at the place it is used.
    // This is due to a successful login which loads <AuthorizedLayout />.
    if (redirect?.redirectPath) sessionStorage.setItem('redirectPath', redirect.redirectPath)
    if (redirect?.redirectSearch) sessionStorage.setItem('redirectSearch', redirect.redirectSearch)
  }, [])

  const clearCurrentUser = useCallback((): void => {
    clearTokensFromStorage()
    setAccessToken(null)
    setLoggedIn(false)
  }, [])

  const storeTokens = useCallback((storage: Storage, tokens: { accessToken: string; refreshToken: string }) => {
    storage.setItem(storageAccessTokenKey, tokens.accessToken)
    storage.setItem(storageRefreshTokenKey, tokens.refreshToken)

    setAccessToken(tokens.accessToken)
    setLoggedIn(true)
  }, [])

  const logout = useCallback(async (): Promise<void> => {
    const { link } = createNetworkStatusNotifier()
    const client = authorizedClient(null, link, locale)

    client
      .mutate({ mutation: LogoutDocument, variables: { input: {} } })
      .finally(() => {
        clearCurrentUser()
      })
      .catch((err) => {
        console.error('Logout mutation returned error:', err.message)
      })
  }, [clearCurrentUser, locale])

  const handleLoginSuccess = useCallback(
    (tokens: { accessToken: string; refreshToken: string }, remember?: boolean): void => {
      const redirectPath = sessionStorage.getItem('redirectPath')
      const redirectSearch = sessionStorage.getItem('redirectSearch')
      const storage = remember ? localStorage : sessionStorage

      storeTokens(storage, tokens)
      window.history.replaceState({}, '', window.location.pathname)
      setLoggedIn(true)

      const pathname = redirectPath || ''
      navigate({ pathname, ...(!!redirectSearch && { search: redirectSearch }) })

      sessionStorage.removeItem('redirectPath')
      sessionStorage.removeItem('redirectSearch')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const login = useCallback(
    (
      userEmail: string | undefined,
      password: string | undefined,
      otp: string | undefined,
      remember?: boolean,
      redirect?: TRedirect | undefined
    ): Promise<boolean | void> => {
      clearCurrentUser()

      const client = unauthorizedClient(locale)
      return client
        .mutate({
          mutation: AdminLoginDocument,
          variables: { email: userEmail || '', password: password || '', otp: otp || '' },
        })
        .then(({ data }) => {
          const { result, tokens } = data.reisbalans.adminLogin
          if (tokens.accessToken) {
            if (redirect) storeRedirectInformation(redirect)

            handleLoginSuccess(tokens, remember)
          }
          return !result.error
        })
        .catch((err) => console.error('Login mutation returned error:', err.message))
    },
    [clearCurrentUser, handleLoginSuccess, locale, storeRedirectInformation]
  )

  const reauthorize = useCallback(
    (token?: string): Promise<string> => {
      if (isRefreshingToken) return Promise.resolve('')

      return new Promise((resolve, reject) => {
        isRefreshingToken = true

        const onRefreshError = (): void => {
          logout()
            .finally(() => navigate(routes[ROUTE_NAMES.SESSION_EXPIRED]))
            .catch(() => {})
        }

        const client = unauthorizedClient(locale, onRefreshError)

        client
          .query({ query: ReauthorizeDocument, variables: { refreshToken: token } })
          .then(({ data }) => {
            const storage = sessionStorage.getItem(storageAccessTokenKey) ? sessionStorage : localStorage

            storeTokens(storage, data.reisbalans.reauthorize)
            resolve(data.reisbalans.reauthorize.accessToken)
          })
          .finally(() => {
            isRefreshingToken = false
          })
          .catch(reject)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearCurrentUser, logout, locale]
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    // @TODO: jwt en refresh_token worden uiteindelijk omgezet naar accessToken en refreshToken door RBC
    const paramAccessToken = params.get('accessToken') || params.get('jwt')
    const paramRefreshToken = params.get('refreshToken') || params.get('refresh_token')

    if (paramAccessToken && paramRefreshToken) {
      // make sure to delete these to be able to clear them from the url
      params.delete('jwt')
      params.delete('refresh_token')
      params.delete('accessToken')
      params.delete('refreshToken')

      // The redirectPath is stored in the sessionStorage because otherwise it is not accessible at the place it is used.
      // This is due to a successful login which loads <AuthorizedLayout />.
      const redirectPath = window.location.pathname

      storeRedirectInformation({ redirectPath, redirectSearch: params.toString() })
      handleLoginSuccess({ accessToken: paramAccessToken, refreshToken: paramRefreshToken })
    }
  }, [handleLoginSuccess, storeRedirectInformation])

  const reauthorizeWithRefreshToken = useCallback((): Promise<string> => {
    return reauthorize(
      sessionStorage.getItem(storageRefreshTokenKey) || localStorage.getItem(storageRefreshTokenKey) || ''
    )
  }, [reauthorize])

  const reauthorizeWithToken = useCallback(
    (token: string): Promise<string> => {
      clearCurrentUser()

      return reauthorize(token)
    },
    [clearCurrentUser, reauthorize]
  )

  const options = useMemo(() => {
    return { accessToken, login, logout, loggedIn, reauthorizeWithToken, reauthorizeWithRefreshToken }
  }, [accessToken, login, logout, loggedIn, reauthorizeWithToken, reauthorizeWithRefreshToken])

  return <AuthContext.Provider value={options}>{children}</AuthContext.Provider>
}
