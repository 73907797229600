import { ApolloProvider } from '@apollo/client'
import React, { Suspense, useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { unauthorizedClient } from '../../Lib/Apollo/apolloClient'
import SessionExpiredPage from '../../Pages/ErrorPages/401'
import { Login } from '../../Pages/Login'
import { AuthContext } from '../../Providers/AuthProvider'
import { TRedirect } from '../../Providers/AuthProvider/interfaces'
import { LocaleContext } from '../../Providers/LocaleProvider'
import { ThemeProvider } from '../../Providers/ThemeProvider'
import { ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const Router: React.FC = (): JSX.Element => {
  const location = useLocation()

  const { locale } = useContext(LocaleContext)
  const { loggedIn } = useContext(AuthContext)

  const redirectPath = window.location.pathname
  const redirectSearch = window.location.search

  const redirect = { redirectPath, redirectSearch } as TRedirect

  return (
    <ApolloProvider client={unauthorizedClient(locale)}>
      <ThemeProvider>
        <TransitionGroup className={Styles.appRoutesTransitionGroup}>
          <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} className="fade">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path={routes[ROUTE_NAMES.LOGIN]} element={<Login />} />
                <Route path={routes[ROUTE_NAMES.SESSION_EXPIRED]} element={<SessionExpiredPage />} />

                <Route path="*" element={!loggedIn ? <Navigate to="/" state={{ redirect }} /> : null} />
              </Routes>
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export const UnauthorizedLayout: React.FC = (): JSX.Element => {
  return (
    <div className={Styles.unauthorizedLayout}>
      <div className={Styles.content}>
        <Router />
      </div>
    </div>
  )
}
