import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Button, ConfirmModal } from '../../../Components'
import ExpandableText from '../../../Components/ExpandableText'
import { Select } from '../../../Components/FormComponents/Select'
import { Icon } from '../../../Components/Icon'
import { Link } from '../../../Components/Link'
import { Message } from '../../../Components/Message'
import { PageContainer } from '../../../Components/PageContainer'
import { Table } from '../../../Components/Table'
import { useTranslationDecorator } from '../../../Hooks/useTranslationDecorator'
import {
  GetBudgetGroupsDocument,
  GetWrpmDocument,
  IV2BudgetGroup,
  useCloseWrpmSurveyMutation,
  useExportWrpmLazyQuery,
  useGetBudgetGroupsQuery,
  useGetWrpmQuery,
} from '../../../Lib/graphql'
import { LayoutContext } from '../../../Providers/LayoutProvider'
import Colors from '../../../Theme/colors.module.scss'
import { EditBudgetGroupSource } from './Components/EditBudgetGroupSource'
import { InviteBudgetGroups } from './Components/InviteBudgetGroups'
import { PollingNotification } from './Components/PollingNotification'
import { ProgressInvitedEmployees } from './Components/ProgressInvitedEmployees'
import { SurveyResponseBudgetGroups } from './Components/SurveyResponseBudgetGroups'
import {
  calculateStatistics,
  downloadAsFile,
  generateYearArray,
  getTableConfig,
  hasOpenSurveys,
  isDataDefinitive,
  makeBudgetGroupOptions,
  mapModalityEnumToIconName,
  transformWRPMData,
} from './helpers'
import {
  IBudgetGroup,
  IBudgetGroupOption,
  IBudgetGroupWithSurvey,
  IStatistics,
  ITransformedWRPMRow,
  IWRPMTableRow,
  IYearOptions,
} from './interfaces'
import Styles from './styles.module.scss'

export const WRPMOverview: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { organizationId } = useContext(LayoutContext) // FIXME: how to inform the user when there is no organization_id?
  const { locale } = useTranslationDecorator()

  const yearOptions = generateYearArray()
  const desiredYear = Math.max(2024, new Date().getFullYear() - 1)
  const defaultYear = yearOptions.find((option) => option.value === desiredYear) || yearOptions[0]

  const [closeWrpmSurveyMutation] = useCloseWrpmSurveyMutation()
  const [exportWrpmQuery] = useExportWrpmLazyQuery()

  const [selectedBudgetGroup, setSelectedBudgetGroup] = useState<IV2BudgetGroup | null>(null)
  const [budgetGroupOptions, setBudgetGroupOptions] = useState<IBudgetGroupOption[]>([])
  const [showExportButton, setShowExportButton] = useState<boolean>(false)
  const [showFinalizeConfirmModal, setShowFinalizeConfirmModal] = useState<boolean>(false)
  const [showExportConfirmModal, setShowExportConfirmModal] = useState<boolean>(false)
  const [selectedYear, setSelectedYear] = useState<IYearOptions>(defaultYear)
  const [showEditBudgetGroupSource, setShowEditBudgetGroupSource] = useState<boolean>(false)

  const [statistics, setStatistics] = useState<IStatistics>({
    lastInvitation: null,
    lastReminder: null,
    respondents: null,
    invitees: null,
    thresholdMet: null,
  })

  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false)
  const [showResponseSideDrawer, setShowResponseSideDrawer] = useState<boolean>(false)
  const [showMessage, setShowMessage] = useState<boolean>(true)

  const { data: budgetGroupsData, loading: isLoadingBudgetGroups } = useGetBudgetGroupsQuery({
    variables: { organizationId: `${organizationId}`, forYear: selectedYear.value },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    skip: !organizationId,
  })

  const selectedBudgetGroups = selectedBudgetGroup
    ? [selectedBudgetGroup.name!]
    : [...budgetGroupOptions.slice(1)].map(({ label }) => label)

  const {
    data: wrpmData,
    startPolling,
    stopPolling,
  } = useGetWrpmQuery({
    variables: {
      organizationId: `${organizationId}`,
      budgetGroupNames: selectedBudgetGroups,
      forYear: selectedYear.value,
    },
    skip: !organizationId || !selectedBudgetGroups.length,
  })

  const handleBudgetGroupChange = (option: IBudgetGroupOption): void => {
    setSelectedBudgetGroup(option.value)
  }

  const handleConfirmFinalizeClick = (): void => {
    const budgetGroups = budgetGroupsData?.reisbalansV2?.v2Organization?.budgetGroupsForWrpm || []
    const budgetGroupNames = budgetGroups.map((budgetGroup) => budgetGroup.name!)

    closeWrpmSurveyMutation({
      variables: { input: { organizationId: `${organizationId}`, year: selectedYear.value } },
      refetchQueries: [
        {
          query: GetBudgetGroupsDocument,
          variables: {
            organizationId,
            forYear: selectedYear.value,
          },
        },
        {
          query: GetWrpmDocument,
          variables: { organizationId, budgetGroupNames, forYear: selectedYear.value },
        },
      ],
    })
      .then(() => setShowFinalizeConfirmModal(false))
      .catch(() => {
        toast.error(t('An error has occurred'))
      })
  }

  const handleConfirmExportClick = (): void => {
    exportWrpmQuery({ variables: { organizationId: `${organizationId}`, forYear: selectedYear.value } })
      .then((response) => {
        const base64 = response.data?.reisbalansV2?.v2Organization?.wrpmExportFile?.xlsFile

        if (base64) downloadAsFile(base64)

        setShowExportConfirmModal(false)
      })
      .catch(() => {
        toast.error(t('An error has occurred'))
      })
  }

  // FIXME this should be moved to helpers.ts
  const getKindCellContent = (item: ITransformedWRPMRow): JSX.Element => {
    return (
      <div className={Styles.modalityKindWrapper}>
        {item.kind ? <Icon name={mapModalityEnumToIconName(item.kind)} /> : ''} {item.humanized}
      </div>
    )
  }

  // FIXME this should be moved to helpers.ts
  const createTableData = (transformedWRPMData: ITransformedWRPMRow[]): IWRPMTableRow[] => {
    const tableRows: IWRPMTableRow[] = []

    transformedWRPMData.forEach((item) => {
      tableRows.push({
        id: item.id,
        kind: getKindCellContent(item),
        commute_kms: `${item.commute_kms.toLocaleString('nl')} ${t('km')}`,
        commute_co2: `${item.commute_co2.toLocaleString('nl')} ${t('gram')}`,
        business_kms: `${item.business_kms.toLocaleString('nl')} ${t('km')}`,
        business_co2: `${item.business_co2.toLocaleString('nl')} ${t('gram')}`,
      })
    })

    return tableRows
  }

  useEffect(() => {
    startPolling(5000)

    if (wrpmData?.reisbalansV2?.wrpm) stopPolling()

    return () => {
      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrpmData])

  useEffect(() => {
    const budgetGroups = budgetGroupsData?.reisbalansV2?.v2Organization?.budgetGroupsForWrpm || []

    if (budgetGroups.length) {
      setStatistics(calculateStatistics(budgetGroups as IBudgetGroupWithSurvey[], locale))
      setShowExportButton(isDataDefinitive(budgetGroups as IBudgetGroup[]))
      setBudgetGroupOptions(() => {
        const options = makeBudgetGroupOptions(budgetGroups)

        if (options.length) {
          if (selectedBudgetGroup) {
            const budgetGroup = budgetGroups.find(({ name }) => name === selectedBudgetGroup.name)

            // if a budget group was selected before refetching budget groups this budget group should be selected again
            if (budgetGroup) setSelectedBudgetGroup(budgetGroup)
          } else setSelectedBudgetGroup(options[0].value)
        }

        return options
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetGroupsData, locale])

  const tableData = useMemo(() => {
    if (!wrpmData) return []

    return createTableData(transformWRPMData(wrpmData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrpmData])

  const budgetGroupsForWrpm = budgetGroupsData?.reisbalansV2?.v2Organization.budgetGroupsForWrpm || []
  const showEditBudgetGroupSourceButton =
    selectedBudgetGroup && !isDataDefinitive(budgetGroupsForWrpm as IBudgetGroup[])

  const description = (
    <Trans
      i18nKey="In the context of the <portalLink>{{ linkText }}</portalLink> it is mandatory to provide a report of the kilometers traveled by your employees. More information can be found on the <websiteLink>{{ websiteText }}</websiteLink>."
      values={{
        linkText: t('reporting obligation for work-related personal mobility'),
        websiteText: t('website of the RVO'),
      }}
      components={{
        portalLink: (
          <Link
            href="https://www.rvo.nl/onderwerpen/rapportage-wpm"
            ariaLabel={t('reporting obligation for work-related personal mobility')}
            style={{ color: Colors.blue500 }}
          />
        ),
        websiteLink: (
          <Link
            href="https://www.rvo.nl/onderwerpen/rapportage-wpm"
            ariaLabel={t('website of the RVO')}
            style={{ color: Colors.blue500 }}
          />
        ),
      }}
    />
  )

  return (
    <PageContainer
      header={{
        title: t('Passenger mobility legislation'),
        showLine: true,
      }}
    >
      <Fragment>
        {showMessage && (
          <Message
            variant="warning"
            description={<ExpandableText text={description} />}
            descriptionElement="div"
            className={Styles.message}
            iconProps={{
              icon: 'cross',
              onClick: (): void => setShowMessage(!showMessage),
            }}
          />
        )}

        <div className={Styles.yearSelectWrapper}>
          <Select
            id="data-year"
            ariaLabel={t('Year')}
            value={selectedYear}
            options={generateYearArray()}
            onChange={(year): void => setSelectedYear(year)}
          />
        </div>

        <ProgressInvitedEmployees
          statistics={statistics}
          isDataDefinitive={isDataDefinitive(budgetGroupsForWrpm as IBudgetGroup[])}
          onInviteButtonClick={(): void => setShowSideDrawer(true)}
          onResponseButtonClick={(): void => setShowResponseSideDrawer(true)}
          hasOpenSurveys={hasOpenSurveys(budgetGroupsForWrpm as IBudgetGroup[])}
        />

        <div className={Styles.controlsContainer}>
          <div className={Styles.budgetGroupControls}>
            <div className={Styles.budgetGroupWrapper}>
              <Select
                id="budgetgroup"
                ariaLabel={t('Budgetgroup')}
                value={budgetGroupOptions.find(({ value }) => value === selectedBudgetGroup)}
                options={budgetGroupOptions}
                onChange={handleBudgetGroupChange}
              />
            </div>

            {showEditBudgetGroupSourceButton && (
              <Button variant="primary" size="medium" onClick={(): void => setShowEditBudgetGroupSource(true)}>
                {t('Edit data')}
              </Button>
            )}
          </div>

          {!isLoadingBudgetGroups && !showExportButton && (
            <Button variant="primary" size="large" onClick={(): void => setShowFinalizeConfirmModal(true)}>
              {t('Finalize')}
            </Button>
          )}

          {showExportButton && (
            <Button
              variant="primary"
              size="large"
              icon="download"
              onClick={(): void => setShowExportConfirmModal(true)}
            >
              {t('Export')}
            </Button>
          )}
        </div>

        {!wrpmData?.reisbalansV2?.wrpm && <PollingNotification year={selectedYear.value} />}

        {wrpmData?.reisbalansV2?.wrpm && <Table tableConfig={getTableConfig()} tableData={tableData} />}

        {showSideDrawer && budgetGroupsForWrpm && (
          <InviteBudgetGroups
            budgetGroups={budgetGroupsForWrpm}
            year={selectedYear.value}
            onClose={(): void => setShowSideDrawer(false)}
          />
        )}

        {showResponseSideDrawer && (
          <SurveyResponseBudgetGroups
            budgetGroups={budgetGroupsForWrpm}
            onClose={(): void => setShowResponseSideDrawer(false)}
          />
        )}

        {showEditBudgetGroupSource && (
          <EditBudgetGroupSource
            budgetGroup={selectedBudgetGroup!}
            organizationId={`${organizationId}`}
            data={wrpmData?.reisbalansV2.wrpm || []}
            year={selectedYear.value}
            onClose={(): void => setShowEditBudgetGroupSource(false)}
          />
        )}

        {showFinalizeConfirmModal && (
          <ConfirmModal
            content={t('Finalize')}
            description={t(
              'This will save the data, and all invited employees who have not yet filled in their travel data will receive an email notifying them that the survey has been closed. This action is irreversible.'
            )}
            cancelContent={t('Cancel')}
            onCancel={(): void => setShowFinalizeConfirmModal(false)}
            confirmButtonProps={{
              content: t('Finalize'),
              onClick: handleConfirmFinalizeClick,
            }}
          />
        )}

        {showExportConfirmModal && (
          <ConfirmModal
            content={t('Export overview')}
            description={t('The complete overview will be exported to an Excel file.')}
            cancelContent={t('Cancel')}
            onCancel={(): void => setShowExportConfirmModal(false)}
            confirmButtonProps={{
              content: t('Export_long'),
              onClick: handleConfirmExportClick,
            }}
          />
        )}
      </Fragment>
    </PageContainer>
  )
}
